import { useState, useRef, } from "react"


const DEBOUNCE_TIME = 500

export function useSearch(fn, delay, defaultSearch="") {
    delay = delay || DEBOUNCE_TIME
    const [search, setSearch] = useState(defaultSearch)
    const { current } = useRef({ timer: null });

    const callback = function f(...args) {
        args[0].persist()
        setSearch(args[0].target.value || "")
        if (current.timer) {
            clearTimeout(current.timer);
        }
        current.timer = setTimeout(() => {
            fn.call(null, ...args);
        }, delay);
    }

    return {
        search,
        setSearch,
        callback
    }
}